import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import { Header } from '../components/layout/index.js';
import { Section, Container, Button } from '../components/common/index.js';

import {
  FormGroup,
  FormLabel,
  Input,
  TextArea,
  Optional,
  Select
} from '../components/layout/FormStyles';

const Small = styled.p`
  font-size: 0.8rem;
`;

const onFormSuccess = e => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmissionSuccess',
    formName: 'Intern Form'
  });
};

const Intern = ({ data }) => (
  <>
    <Helmet>
      <title>
        Travel and tourism internships in Australia with Base Backpackers
      </title>
      <meta
        name="description"
        content="Get a head start in the hospo industry with an education from the best with Base Backpackers"
      />
    </Helmet>
    <Header
      backgroundImage={data.file.childImageSharp.fluid}
      pageTitle="Hospitality Internships"
      tagline="Get a head start in the hospo industry with an education from the best"
      // propertyName="Base Magnetic Island"
      // caption="Sunset Walking Tour"
    />

    <Section>
      <Container>
        <p>
          Our hospitality internships in Australia give the chance to learn from
          some of the best in Australia’s tourism industry, with more than 100
          years of experience between key Senior Manager’s.
        </p>
        <p>
          You’ll participate in a formalised hospitality internship program that
          will develop your accommodation management skills.
        </p>
        <p>
          This internship program aims to identify the next generation of top
          talent, with the best performers invited to join our paid Trainee
          General Management program, where you’ll be on the pathway to becoming
          a superstar General Manager and placed in a property of your own to
          manage in the future.
        </p>

        <h2>Who are we?</h2>
        <p>
          We’re the people that create extraordinary adventure travel
          experiences for youth travellers from all around the World visiting
          Australia and New Zealand.
        </p>
        <p>
          With multiple brands across all aspects of youth travel, we are one of
          the fastest growing tourism companies in Australia. Our offerings
          include accommodation, bars and restaurants, 4wd tours, dive shops,
          surf schools and more
        </p>
        <p>
          Base &amp; Base Backpackers are on the lookout for superstar interns
          to join the crew and deliver our guests the ultimate action-packed
          adventure holiday.
        </p>
        <p>
          There may be an opportunity for your internship to be based in New
          Zealand
        </p>

        <h2>Who are you?</h2>
        <p>
          You LOVE all things travel whether you’re an experienced traveller
          with time well spent on the road or whether you dream of that day you
          pack your backpack and live the nomadic life, your passion for travel
          shines.
        </p>
        <p>
          You’re energetic but focused on delivering above and beyond.You are
          fun and outgoing, willing to give anything a go and give it your best.
        </p>
        <p>
          You’re studying or have competed tertiary education at a University,
          College, TAFE or equivalent in one of the fields listed below: Tourism
          &amp; Hospitality, Business Administrating, Commerce, Marketing,
          Advertising, Finance, Accounting, Management, Arts, Education.
        </p>

        <h2>What are the opportunities?</h2>
        <p>
          Gain knowledge and understanding across the full marketing mix as we
          develop brand experiences and marketing plans for more than 40
          business located in 28 locations around Australia and in New Zealand.
        </p>
        <p>Some of the key performance area include:</p>
        <h2>Operations</h2>
        <ul>
          <li>
            Customer service – Deliver exceptional customer service and customer
            service training to the crew
          </li>
          <li>
            Reservation systems – learn how to use the reservation and guest
            management systems
          </li>
          <li>
            Front desk management – learn opening and closing procedures, gain
            skills in generating nightly reports and banking procedures.
          </li>
          <li>
            Financial management – gain knowledge and skills in managing
            budgets, P&amp;L analysis, cost control and month end reporting
            production.
          </li>
          <li>
            Food &amp; beverage – gain knowledge in managing COGS and profit
            margins to drive revenue, while gaining skills and understanding in
            bar operations, promotions and crowd management.
          </li>
        </ul>
        <h3>Marketing</h3>
        <ul>
          <li>Digital marketing</li>
          <li>E-commerce</li>
          <li>Advertising</li>
          <li>Creative design</li>
          <li>Content marketing</li>
          <li>Social media</li>
          <li>Data analytics</li>
        </ul>

        <h2>Apply Now</h2>

        {/* Form Here */}

        <form
          name="Intern Form"
          method="POST"
          data-netlify="true"
          onSubmit={onFormSuccess}
          // action="https://getform.io/f/25a5287d-660b-411d-8866-16250ee47883"
          // encType="multipart/form-data"
          // action="/success/"
        >
          <input type="hidden" name="form-name" value="Intern Form" />
          <div style={{ color: 'red', textAlign: 'right' }}>
            <Small>* Required field</Small>
          </div>

          <FormGroup>
            <FormLabel htmlFor="fName">
              First Name:
              <span className="required">*</span>
            </FormLabel>
            <Input
              id="fName"
              type="text"
              name="fName"
              placeholder="Excited Bob"
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="name">
              Last Name:
              <span className="required">*</span>
            </FormLabel>
            <Input
              id="lName"
              type="text"
              name="lName"
              placeholder="Excited Bob"
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="email">
              Your Email:
              <span className="required">*</span>{' '}
            </FormLabel>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder="bob@bringonthemadness.com"
              required
            />
            <Small>We won't share your email with anyone</Small>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="uniName">
              Your University Name
              <span className="required">*</span>
            </FormLabel>
            <Input
              id="uniName"
              type="text"
              name="uniName"
              placeholder="Cambridge University"
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="whatAreYouStudying">
              What you're studying
              <span className="required">*</span>
            </FormLabel>
            <Input
              id="whatAreYouStudying"
              type="text"
              name="whatAreYouStudying"
              placeholder="Tourism Management"
              required
            />
            <Small>Must be related to tourism</Small>
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="about">
              Tell us about yourself: <span className="required">*</span>
            </FormLabel>
            <TextArea
              id="about"
              name="about"
              placeholder="e.g What’s your name, where are you from and what locations would you be most excited to work in? or What are you studying and why?"
              required
            />
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="phone">
              Your Phone Number: <Optional>Optional</Optional>
            </FormLabel>
            <Input
              id="phone"
              type="phone"
              name="phone"
              placeholder="+61 420 420 420"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="groupType">
              What is your prefered location
              <span className="required">*</span>
            </FormLabel>
            <Select id="groupType" name="groupType" required>
              <option value="Sydney">Sydney</option>
              <option value="Melbourne">Melbourne</option>
              <option value="Brisbane">Brisbane</option>
              <option value="Melbourne">Melbourne</option>
              <option value="Wanaka">Wanaka</option>
              <option value="Wellington">Wellington</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="commencementDate">
              Approximate Commencement date of your internship:
              <span className="required">*</span>
            </FormLabel>
            <Input
              id="commencementDate"
              type="text"
              name="commencementDate"
              placeholder="June 2020"
              required
            />
            <Small>Must be related to tourism</Small>
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="internshipLength">
              Length of your required Internship according to your curriculum:
              <span className="required">*</span>
            </FormLabel>
            <Input
              id="internshipLength"
              type="text"
              name="internshipLength"
              placeholder="3 months"
              required
            />
            <Small>Must be related to tourism</Small>
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="why">
              Why do you stand out from the crowd?{' '}
              <span className="required">*</span>
            </FormLabel>
            <TextArea
              id="why"
              name="why"
              placeholder="I can tame a giraff by singing Lady Gaga's - Bad Romance"
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="cv">
              Upload your CV/Resume
              <span className="required">*</span>
            </FormLabel>
            <input type="file" name="cv" style={{ display: 'block' }} />
          </FormGroup>

          <p style={{ display: 'none' }}>
            <label>
              Don’t fill this out if you're human:{' '}
              <input type="hidden" name="_gotcha" />
            </label>
          </p>
          <FormGroup>
            <Button primary type="submit">
              Send it!
            </Button>
          </FormGroup>
        </form>
      </Container>
    </Section>
  </>
);

export default Intern;

export const query = graphql`
  query {
    file(relativePath: { eq: "1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
